import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import { Auth } from 'aws-amplify'
import { Link, Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { validateFieldUsers } from '../../constants/validator'
import { AlertMessage } from '../../contents/AlertMessage'
import { ButtonSubmit } from '../../contents/Buttons'
import { Input } from '../../contents/Input'
import { traslateMessage } from '../../utils/AwsCodes'

export default function SignUpConfirmForm(props) {
  let msgErrorField = {}
  const classes = useStyles()
  const [code, setCode] = useState('')
  const [sub, setSub] = useState('')
  const [loading, setLoading] = useState(false)
  const [submit, setSubmit] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)
  const [msg, setMgs] = useState({})

  useEffect(() => {
    const { search } = props.location
    ;(async () => {
      if (search) {
        let searchParams = new URLSearchParams(search)
        for (let data of searchParams) {
          if (data[0] === 'sub') {
            setSub(data[1])
          }
        }
      }
    })()
  }, [props])

  const handleVerifyRegister = async event => {
    event.preventDefault()
    setSubmit(true)
    handleClose()
    if (validateFieldUsers({ code }).is_valid) {
      setLoading(true)
      await Auth.confirmSignUp(sub, code)
        .then(async response => {
          navigate('/signin/', {
            replace: true,
            state: {
              msg: {
                message: 'Tu cuenta se verificar exitosamente',
                type: 'success',
              },
            },
          })
        })
        .catch(error => {
          console.log('error', error)
          handleOpenAlert({
            type: 'error',
            message: traslateMessage(error),
          })
        })
      setLoading(false)
    }
  }

  const handleResendCode = async () => {
    handleClose()

    await Auth.resendSignUp(sub)
      .then(response => {
        handleOpenAlert({
          message: 'Código envido con exito.',
          type: 'success',
        })
      })
      .catch(error => {
        handleOpenAlert({
          type: 'error',
          message: traslateMessage(error),
        })
      })
  }

  const handleClose = () => {
    setOpenAlert(false)
    setMgs({})
  }

  const handleOpenAlert = message => {
    setMgs(message)
    setOpenAlert(true)
  }

  if (submit) {
    msgErrorField = validateFieldUsers({ code }).msg
  }

  return (
    <div>
      <AlertMessage msg={msg} openAlert={openAlert} handleClose={handleClose} />
      <form
        className={classes.form}
        autoComplete="off"
        onSubmit={handleVerifyRegister}
      >
        <Input
          name="verifi_code"
          type="text"
          placeholder="Código"
          value={code}
          loading={loading}
          msgErrorField={msgErrorField}
          setCallBack={setCode}
        />
        <Box textAlign="left" paddingTop="0.5em">
          <Link className={classes.active} onClick={() => handleResendCode()}>
            <Typography variant="body2"> Reenviar codigo</Typography>
          </Link>
        </Box>
        <div style={{ paddingTop: '0.2em', paddingBottom: '1em' }}>
          <ButtonSubmit title="Continuar" loading={loading} />
        </div>
      </form>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  form: {
    width: '100%',
    '& > .MuiFormControl-root > label': {
      fontSize: '14px',
    },
  },
  error: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '-14px',
    '& > * + *': {
      paddingLeft: '0.5em',
      fontWeight: '550',
    },
  },
  active: {
    color: '#60D957',
    cursor: 'pointer',
    '&:active': {
      color: '#60D957',
    },
    '& > .MuiTypography-body2': {
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '22px',
    },
    buttonSubmit: {
      marginTop: '3em',
    },
  },
}))
