import React, { useEffect } from 'react'
import { withWidth } from '@material-ui/core'
import { isLoggedIn } from '../../utils/auth'
import { navigate } from 'gatsby'
import Header from '../../contents/Header'
import InitUserPage from '../../contents/InitUserPage'
import SignUpForm from './SignUpForm'
import SignUpConfirmForm from './SignUpConfirmForm'

function SignUpPage(props) {
  const { search } = props.location
  let context = {}

  useEffect(() => {
    ;(async () => {
      if (await isLoggedIn()) {
        //navigate('/app/profile')
        navigate('/dashboard/')
      }
    })()
  }, [])

  if (search) {
    context = (
      <InitUserPage {...props} title="Confirmar cuenta">
        <SignUpConfirmForm />
      </InitUserPage>
    )
  } else {
    context = (
      <InitUserPage {...props} title="Crear cuenta">
        <SignUpForm />
      </InitUserPage>
    )
  }

  return (
    <div>
      <Header {...props} menuRight={null} />
      {context}
    </div>
  )
}

export default withWidth()(SignUpPage)
