import React from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ErrorFields } from './FieldsMessage'

export function Select(props) {
  const {
    label,
    placeholder,
    value,
    setCallBack,
    loading,
    msgErrorField,
    name,
    trim,
    inputRef,
    variant,
    currencies,
    keyName,
    labelName
  } = props
  const classes = useStyles()

  return (
    <TextField
      inputRef={inputRef}
      margin="normal"
      variant={variant ? variant : 'outlined'}
      required={false}
      name={name}
      fullWidth
      select
      label={label}
      placeholder={placeholder}
      size="small"
      value={value}
      disabled={loading}
      error={msgErrorField ? (msgErrorField[name] ? true : false) : false}
      helperText={
        msgErrorField ? (
          msgErrorField[name] ? (
            <ErrorFields msg={msgErrorField[name]} />
          ) : (
            false
          )
        ) : (
          false
        )
      }
      onChange={({ target: { value } }) =>
        setCallBack ? setCallBack(trim ? value.trim() : value) : null
      }
      className={classes.inputField}
      SelectProps={{
        native: true,
      }}
    >
      {(currencies || []).map((option) => (
        <option
          key={( keyName ? option[keyName] : option.value )}
          value={( keyName ? option[keyName] : option.value )}
          disabled={option.disabled}
        >
          {( labelName ? option[labelName] : option.label )}
        </option>
      ))}
    </TextField>
  )
}

Select.propTypes = {
  helperText: PropTypes.any,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  setCallBack: PropTypes.func,
}
const useStyles = makeStyles((theme) => ({
  inputField: {
    marginBottom: '0px',
    '& select': {
      border: '#000',
      color: '#000000',
      fontSize: '12px',
      '&::placeholder': {
        fontWeight: 200,
        fontFamily: 'sans-serif',
        opacity: 1,
      },
    },
    '& label': {
      fontSize: '18px',
    },
  },
}))
