import React, { useState } from 'react'
import { navigate } from 'gatsby'
import { Auth } from 'aws-amplify'
import { makeStyles } from '@material-ui/core/styles'
import { validateFieldUsers } from '../../constants/validator'
import { AlertMessage } from '../../contents/AlertMessage'
import { ButtonSubmit } from '../../contents/Buttons'
import { Input } from '../../contents/Input'
import { traslateMessage } from '../../utils/AwsCodes'
import { Select } from '../../contents/Select'


export default function SignUpForm(props) {
  let msgErrorField = {}
  const classes = useStyles()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [webSiteProtocol, setWebSiteProtocol] = useState('')
  const [webSite, setWebSite] = useState('')
  const [loading, setLoading] = useState(false)
  const [submit, setSubmit] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)
  const [msg, setMgs] = useState({})

  const signUp = async event => {
    event.preventDefault()
    if ( webSiteProtocol === '' ) {
      setOpenAlert(true)
      setMgs({
        type: 'error',
        message: 'Debes seleccionar el protocolo de tu página',
      })
      return
    }
    setSubmit(true)
    setMgs({})
    setOpenAlert(false)
    if (
      validateFieldUsers({
        email,
        password,
        webSite: webSiteProtocol + webSite,
      }).is_valid
      && webSiteProtocol
    ) {
      setLoading(true)
      await Auth.signUp({
        username: email,
        password,
        attributes: { email, website: webSiteProtocol + webSite}
      })
        .then(async response => {
          console.log('user3', response)
          navigate(`/signup/?sub=${response.userSub}`, {
            replace: true,
          })
        })
        .catch(error => {
          console.log('error', error)
          setMgs({
            type: 'error',
            message: traslateMessage(error),
          })
          setOpenAlert(true)
        })
      setLoading(false)
    }
  }

  const handleClose = () => {
    setOpenAlert(false)
    setMgs({})
  }
  if (submit) {
    msgErrorField = validateFieldUsers({
      email,
      password,
      webSite: webSiteProtocol + webSite,
    }).msg
  }
  return (
    <div>
      <AlertMessage msg={msg} openAlert={openAlert} handleClose={handleClose} />
      <form
        className={classes.form}
        noValidate
        autoComplete="off"
        onSubmit={signUp}
      >
        <Input
          name="email"
          type="text"
          placeholder="E-mail"
          value={email}
          loading={loading}
          msgErrorField={msgErrorField}
          setCallBack={setEmail}
        />
        <Input
          name="password"
          type="password"
          placeholder="Contraseña"
          value={password}
          loading={loading}
          msgErrorField={msgErrorField}
          setCallBack={setPassword}
        />
        <Select
          name="license"
          currencies={[{label: 'Seleccione el protocolo', value: ''}, {label: 'http://', value: 'http://'}, {label: 'https://', value: 'https://'}]}
          value={ webSiteProtocol }
          loading={loading}
          msgErrorField={msg}
          setCallBack={setWebSiteProtocol}
        />
        <Input
          name="webSite"
          type="text"
          placeholder="Site web"
          value={webSite}
          loading={loading}
          msgErrorField={msgErrorField}
          setCallBack={setWebSite}
        />
        <div style={{ marginTop: '0.2em' }}>
          <ButtonSubmit title="Crear cuenta" loading={loading} />
        </div>
      </form>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%',
    '& > .MuiFormControl-root > label': {
      fontSize: '14px',
    },
  },
  error: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '-14px',
    '& > * + *': {
      paddingLeft: '0.5em',
      fontWeight: '550',
    },
  }
}))
